<template>
  <div class="main">
    <div class="wxguide">
      <p>
        <span class="wxguide_btn">1</span>
        点击右上角的按钮
        <img class="wxguide_img" src="../../../src/assets/images/jt.png" />
      </p>
      <p mt9>
        <span
          class="w-22 h-22 lh-22 rd-50% border-#D8D8D8 border-solid border-1 inline-block"
          >2</span
        >
        选择在<span class="text-#5266F8">"浏览器中打开"</span>
      </p>
      <div style="margin-top: 88px">
        <img width="160" src="../../../src/assets/images/logo_new.png" />
      </div>
    </div>
    <div class="pageMain">
      <a-button
        class="btn_down"
        style="margin-top: 40px"
        type="primary"
        @click="downApp"
        >下载APP</a-button
      >
      <div>
        已有Dana AI 智能体？<a class="openA" href="#" @click="onError">打开</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "Hello, Vue!",
    };
  },
  methods: {
    onError() {
      // danaai://csii.app.net/?pagetype=1
      const ua = navigator.userAgent.toLowerCase();
      if (!!/micromessenger/.test(ua)) {
        this.wxOpenBtn = true;
        this.btn = true;
        const { link, resultMesssage, btn, state, resultPage } = this;
        const url =
          location.origin +
          location.pathname +
          "?" +
          this.toQueryString({ link, resultMesssage, state, btn, resultPage });
        console.log(url);
        open(url, "_self");
        return;
      }
      location.href = "danaai://csii.app.net/?pagetype=1";
    },
    downApp() {
      const ua = navigator.userAgent.toLowerCase();
      if (!!/micromessenger/.test(ua)) {
        this.wxOpenBtn = true;
        this.btn = true;
        const { link, resultMesssage, btn, state, resultPage } = this;
        const url =
          location.origin +
          location.pathname +
          "?" +
          this.toQueryString({ link, resultMesssage, state, btn, resultPage });
        console.log(url);
        open(url, "_self");
        return;
      }

      const test = /\(i[^;]+;( U;)? CPU.+Mac OS X/; //
      // 判断是否是 iOS终端)
      if (!!navigator.userAgent.match(test)) {
        return this.$message.warning(
          "很抱歉，我们的APP目前不支持IOS版本，请使用其他设备下载。"
        );
      }
      open(
        "http://gidc.tel365.com/gidc-app/app/issue/resource/download.action?projectId=E712C313315A43E486ACD62CEC7C65AF&systemType=1"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.protocol-container {
  padding: 30px 0 8px;

  .protocol {
    color: #1890ff;
  }
}

.main {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: #eee;

  .linkfail {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
}

.pageMain {
  background: #fff;
  max-width: 375px;
  height: 100vh;
  max-height: 768px;
  padding: 10px;
  width: 100%;
  margin: 10px auto;
  overflow: scroll;

  // text-align: center;
  .content {
    margin-top: 20px;
    max-height: 300px;

    /deep/ .el-input__suffix {
      right: 0 !important;
    }

    /deep/ .ant-btn {
      color: #fff;
      background-color: #5c92ff;
      border-color: #5c92ff;
    }
  }

  .title {
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: #333;
  }
}

.wxguide {
  color: #333;
  margin-top: 58px;
  text-align: center;
  font-size: 17px;

  .wxguide_img {
    position: absolute;
    width: 69px;
    top: 22px;
    right: 1px // absolute w-89 top-4 right-21 ;
  }

  .wxguide_btn {
    // w-22 h-22  lh-22  rd-50% border-#D8D8D8 border-solid border-1 inline-block
  }
}

.btn_down {
  max-width: 343px;
  height: 50px;
  width: 91.5vw;
  background: #3271ff;
  font-size: 16px;
  font-weight: 500;
  margin: 16px auto;
}
</style>
