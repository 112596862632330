
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import HeaderUser from "@/components/layouts/HeaderComponents/HeaderUser.vue";
import PreviewImg from "@/components/previewImg/index.vue";
import SaveModal from "@/views/popup/moveModal.vue";
import MobileLayouts from "@/components/Mobile/MobileLayouts.vue";
import Login from "@/views/User/Login.vue";
import { imgEx, onlineEx } from "@/common/utils/fileEx";
import { creatClientId } from "@/common/utils/util";
import { Alert } from "ant-design-vue";
import dayjs from "dayjs";
import CountDown from "@/views/User/components/mixin/countDown";
// import { docFileView } from "../../common/utils/docFilePreview";
// @ts-ignore
import { publicFormatSize, publicFileView } from "@/utils/index";
import { mixins } from "vue-class-component";

import OpenApp from "./OpenApp.vue";

const UserModule = namespace("user");
const shareModule = namespace("share");
const CommonModule = namespace("common");

@Component({
  name: "FileShare",
  components: {
    OpenApp,
    HeaderUser,
    PreviewImg,
    Login,
    SaveModal,
    MobileLayouts,
    [Alert.name]: Alert,
  },
  filters: {
    //局部过滤器
    unitTransform(size: number) {
      let val: string | number;
      if (size < 1024) {
        val = size + "B";
      } else if (size < 1024 * 1024) {
        val = size / 1024;
        val = val.toFixed(2) + "KB";
      } else if (size < 1024 * 1024 * 1024) {
        val = size / (1024 * 1024);
        val = val.toFixed(2) + "MB";
      } else {
        val = size / (1024 * 1024 * 1024);
        val = val.toFixed(2) + "GB";
      }
      return val;
    },
  },
})
export default class FileShare extends mixins(CountDown) {
  [x: string]: any;
  @CommonModule.State("navigator") navigator!: string;
  // @UserModule.Mutation("SET_USER") setUser!: ({ }) => void;
  @UserModule.State("token") token!: string;
  @UserModule.State("openOrNotStarDocFlag") openOrNotStarDocFlag!: boolean;

  // 临时改为false调试
  // public openOrNotStarDocFlag: boolean = false;
  @UserModule.State("companyList") companyList!: any[];
  @UserModule.State("companyId") companyId!: string;
  // @UserModule.Action("GET_USERINFO") getUserinfo!: (type?: string) => void;
  @shareModule.State("loginShow") loginShow!: boolean;

  @shareModule.Mutation("SET_LOGINSHOW") setLoginShow!: (
    value: boolean
  ) => void;

  public spin: boolean = false;
  public loading: boolean = false;
  // public showOpenAppFlag: boolean = false;
  // public showOpenAppFlag: boolean = true;
  public show: boolean = false;
  public deviceClass: string = "";

  // 分享信息
  public shareFileList: any = [];
  public shareId: string = "";
  public shareFileDetails: any = {};
  public accessPwd: string = "";
  // 分享文件父id,查看文件夹下级文件时携带
  public parentFileId: string | undefined = undefined;
  // 是否私密分享
  public isPrivate: boolean = false;
  public shareDetails: boolean = false;

  // 表格项
  public columns: any = [
    {
      title: "名称",
      dataIndex: "userFileName",
      align: "left",
      scopedSlots: { customRender: "userFileName" },
    },
    {
      title: "创建时间",
      dataIndex: "creatTime",
      align: "center",
      customRender(value: string | number) {
        // return dayjs(value).format("YYYY-MM-DD HH:mm");
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    // {
    //   title: "备注",
    //   dataIndex: "remark",
    //   align: "center",
    // },
    {
      title: "文件大小",
      dataIndex: "userFileSize",
      align: "center",
      scopedSlots: { customRender: "size" },
      customRender(size: number, record: any) {
        if (record.isDir !== 1) {
          let val: string | number;
          if (size < 1024) {
            val = size + "B";
          } else if (size < 1024 * 1024) {
            val = size / 1024;
            val = val.toFixed(2) + "KB";
          } else if (size < 1024 * 1024 * 1024) {
            val = size / (1024 * 1024);
            val = val.toFixed(2) + "MB";
          } else {
            val = size / (1024 * 1024 * 1024);
            val = val.toFixed(2) + "GB";
          }
          return val;
        } else {
          return "-";
        }
      },
    },
    {
      title: "操作",
      dataIndex: "",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];
  // 表格多选
  public selectedRowKeys: Array<Number | string> = [];
  public breadcrumbList: { text: string; id: string | number }[] = [
    { text: "全部文件", id: "" },
  ];

  // 图片预览
  public previewId: any = "";
  public previewImgList: any[] = [];

  // 文件保存
  public saveModalShow: boolean = false;
  public saveOptions: any = { checkIdList: [] };

  // 移动端登录
  public formData: any = {
    loginId: "",
    code: "",
  };
  // 验证规则
  public rules: any = {
    loginId: [
      { required: true, message: "请输入邮箱或手机号", trigger: "blur" },
      // {
      //   pattern:
      //     /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
      //   message: "请输入正确手机号",
      // },
    ],
    code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  };

  // 显示企业
  get userCompany(): string {
    let company = this.companyList.find((i) => i.id === this.companyId);
    return this.companyId === "person" ? "个人文档" : company?.companyName;
  }

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("$route.query", { deep: true, immediate: true })
  change(value: any) {
    this.spin = true;
    this.$apis
      .shareQueryShare({ shareUrl: value.shareKey })
      .then((res: any) => {
        if (res.code === "200") {
          let response = res.data;
          this.isPrivate = response.shareType === "private";
          this.shareFileDetails = response;
          this.shareId = response.id;
        }
      })
      .finally(() => {
        this.spin = false;
      });
  }
  //ref 引用元素 Ref('ref')
  //@Ref()
  @Ref("ruleForm")
  ruleForm!: any;

  // 登录
  login() {
    this.setLoginShow(true);
  }

  // 移动端适配的分享文件列表多选框
  checkboxChanged(
    item: { isSelected: any; userFileId: string | Number },
    event: any
  ) {
    console.log("Checkbox changed:", event.target.checked); // 打印出 event 的值

    let isSelected = event.target.checked;

    item.isSelected = isSelected; // 设置 isSelected 的值
    if (isSelected) {
      // 如果已选中，将其添加到所选文件列表中
      this.selectedRowKeys.push(item.userFileId);
      console.log("1111111111111-- this.selectedRowKeys", this.selectedRowKeys);
    } else {
      // 如果未选中，从所选文件列表中删除
      const index = this.selectedRowKeys.indexOf(item.userFileId);
      if (index !== -1) {
        // 如果 selectedRowKeys 中存在当前 userFileId，则将其移除
        this.selectedRowKeys.splice(index, 1);
      }
      console.log(
        "22222222222222-- this.selectedRowKeys",
        this.selectedRowKeys
      );
    }
  }

  // 移动端适配的分享文件列表的点击预览
  openFile(val:any) {
    console.log('预览')
    let tempFile = {
      ...val,
      moduleType: val.moduleType || "doc",
      actionFileid: val.userFileId,
      actionFileSize: publicFormatSize(val.userFileSize || val.fileSize),
      actionFileFullName: `${val.userFileName}.${val.extensionName}`,
    }
    console.log('预览的参数是',tempFile)
    // @ts-ignore
    publicFileView(tempFile);
  }

  // 移动端适配的分享文件列表的下拉菜单/下载和保存
  handleMenuClick(item: any, event: any) {
    console.log(`Click on item `, item);
    console.log(`Click on event `, event.key);
    if (event.key == 1) {
      this.handleDownload(item);
    } else {
      // 移动端的单个文件保存，文件右侧菜单保存
      this.handleSaveIsMobile(item);
    }
  }

  // 返回首页
  goBackHome() {
    let url = window.location.origin;
    window.location.href = url;
  }

  // 申请开通云文档
  applyForOpening() {
    // alert('申请开通云文档')
    let origin = window.location.origin;
    let url = `${origin}/system/ApplyCenter/ApplyDetail?appId=1542798229211451394&newApp=true`;
    window.location.href = url;
  }

  qiankunClass() {
    return (window as any).__POWERED_BY_QIANKUN__
      ? "file-share-content-width"
      : "qq";
  }

  // 获取验证码
  queryCode(): void {
    if (this.formData.loginId) {
      let params = {
        loginId: this.formData.loginId,
        modelNumber: "001",
      };
      this.isQueryCode = true;
      this.againQueryTime();
      this.$apis.getEmailCode(params).then((res: any) => {
        if (res.code === "200") {
          this.$message.success("操作成功");
        }
      });
    } else {
      this.$message.warning("请输入邮箱或手机号");
    }
  }

  // 移动端登录
  handleLogin(): void {
    this.ruleForm.validate((valid: boolean) => {
      if (valid) {
        this.$apis
          .msgLogin({
            ...this.formData,
          })
          .then((res: any) => {
            if (res.code === "200") {
              sessionStorage.setItem(
                "X-Access-Token",
                JSON.stringify(res.data)
              );
              localStorage.setItem("userId", res.data.userId);

              // this.setUser(res.data);
              // this.getUserinfo("userinfo");
              this.setLoginShow(false);
            } else {
              this.$message.warning(res.msg);
            }
          });
      } else {
        this.$message.warning("请检查输入，不能为空！");
      }
    });
  }

  // 去到首页
  goHome() {
    this.$router.push({ path: "/star" });
  }

  // 切换企业
  changeCompany(company: any) {
    this.$apis
      .companyUpdateDefault({ companyId: company.id })
      .then(async (res: any) => {
        if (res.code === "200") {
          // await this.getUserinfo("userinfo");
          this.$message.success(
            `操作成功，个人文档、协作、分享、收藏等数据即将切换到【${
              company.id === "person" ? "个人文档" : company.companyName
            }】`
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      });
  }

  // 查看分享文件
  handleLook(): void {
    let shareKey = new URL(window.location.href).searchParams.get("shareKey");
    console.log("shareKey", shareKey); // 输出 VR7Z3aEre

    // 可直接打开的文件
    console.log("this.shareFileDetails", this.shareFileDetails);
    // 单个文件查看分享，直接预览
    if (this.shareFileDetails.isView === 1 && this.navigator === "Pc") {
      this.parentFileId = this.shareFileDetails.userFileId;
      // 判断是否文档类型 跳转online office
      if (onlineEx.includes(this.shareFileDetails.extensionName)) {
        let query = {
          userFileId: this.shareFileDetails.userFileId,
          // parentFileId: this.parentFileId,
          moduleType: "share",
          // fileExtension: this.shareFileDetails.extensionName,
          // fileName: this.shareFileDetails.userFileName,
          // shareId: this.shareId,
          // ot: "view",
        };
        let routeData = this.$router.resolve({
          name: "VIEW_OFFICE_ONLINE",
          query,
        });
        // 私密文件校验
        if (this.isPrivate) {
          this.queryShareFileList("getOnlineOffice", routeData.href);
        } else {
          console.log("预览的this.shareFileDetails", this.shareFileDetails);
          let originUrl = `${process.env.VUE_APP_KK_BASE_URL}/document/userFile/preview?userFileId=${this.shareFileDetails.userFileId}&moduleType=share`; //要预览文件的访问地址
          let previewUrl =
            originUrl +
            `&fullfilename=${this.shareFileDetails.userFileName}.${this.shareFileDetails.extensionName}`;
          const encoder = new TextEncoder();
          const data = encoder.encode(previewUrl);
          let binary = "";
          for (let i = 0; i < data.length; i++) {
            binary += String.fromCharCode(data[i]);
          }
          const encodedString = btoa(binary);
          let openUrl = `${
            process.env.VUE_APP_KK_PREVIEW
          }/onlinePreview?url=${encodeURIComponent(encodedString)}`;
          let querys: any = {
            url: openUrl,
            userFileId: this.shareFileDetails.userFileId,
            shareKey: shareKey,
            userFileName: this.shareFileDetails.userFileName,
            extensionName: this.shareFileDetails.extensionName,
            shareId: this.shareId,
          };

          let routeDatas = this.$router.resolve({
            name: "VIEW_File",
            query: querys,
          });
          window.open(routeDatas.href, "_blank"); // 新窗口打开
        }
        // 图片类型预览
      } else if (imgEx.includes(this.shareFileDetails.extensionName)) {
        // this.$apis.shareRecord({
        //   fileName: `${this.shareFileDetails.userFileName}.${this.shareFileDetails.extensionName}`,
        //   shareId: this.shareId,
        //   type: "download",
        // });
        if (this.isPrivate) {
          this.queryShareFileList("getImg");
        } else {
          this.previewImgList = [
            {
              id: this.shareFileDetails.userFileId,
              url: this.getFileUrl(this.shareFileDetails),
            },
          ];
          this.$nextTick(() => {
            this.previewId = this.shareFileDetails.userFileId;
          });
        }
      }
    }
    // return
    this.loading = true;
    setTimeout(() => {
      this.queryShareFileList("getList");
    }, 500);
  }

  // 获取分享文件列表(可用于私密文件的秘钥校验)
  queryShareFileList(type: string, params?: any) {
    this.$apis
      .sharePageShareFiles({
        accessPwd: this.isPrivate ? this.accessPwd : undefined,
        id: this.shareId,
        isPage: 0,
      })
      .then((res: any) => {
        if (res.code === "200") {
          if (type === "getList") {
            this.shareDetails = true;
            this.selectedRowKeys = [];
            this.shareFileList = res.data.list;
            // 为每个对象添加 isSelected 属性并初始化为 false
            this.shareFileList.forEach((item: { isSelected: boolean }) => {
              item.isSelected = false;
            });
            this.loading = false;
          } else if (type === "getImg") {
            this.previewImgList = [
              {
                id: this.shareFileDetails.userFileId,
                url: this.getFileUrl(this.shareFileDetails),
              },
            ];
            this.$nextTick(() => {
              this.previewId = this.shareFileDetails.userFileId;
            });
          } else if (type === "getOnlineOffice") {
            // window.open(params, "_blank"); // 打开文档预览
          }
        }
      });
  }

  // 获取下级文件列表
  queryNextShareFileList(value: any, next?: boolean) {
    this.loading = true;
    // next为true是打开子级文件夹,不传值为面包屑跳转
    this.$apis
      .shareNextFiles({
        id: next ? value.userFileId : value.id,
      })
      .then((res: any) => {
        if (next) {
          this.breadcrumbList.push({
            text: value.userFileName,
            id: value.userFileId,
          });
        }
        this.selectedRowKeys = [];
        this.shareFileList = res.data;
        this.loading = false;
      });
  }

  onSelectChange(selectedRowKeys: Array<Number | string>): void {
    this.selectedRowKeys = selectedRowKeys;
  }

  // 预览结束, 清空标记
  previewHide() {
    this.previewId = null;
  }

  hadnleFile(file: any) {
    let shareKey = new URL(window.location.href).searchParams.get("shareKey");
    console.log("2222-shareKey", shareKey); // 输出 VR7Z3aEre

    if (this.breadcrumbList.length === 1) this.parentFileId = file.userFileId;
    if (file.isDir === 1) {
      this.queryNextShareFileList(file, true);
    } else if (onlineEx.includes(file.extensionName)) {
      console.log("双击打开file", file);

      let originUrl = `${process.env.VUE_APP_KK_BASE_URL}/document/userFile/preview?userFileId=${file.userFileId}&moduleType=share`; //要预览文件的访问地址
      let previewUrl =
        originUrl + `&fullfilename=${file.userFileName}.${file.extensionName}`;
      const encoder = new TextEncoder();
      const data = encoder.encode(previewUrl);
      let binary = "";
      for (let i = 0; i < data.length; i++) {
        binary += String.fromCharCode(data[i]);
      }
      const encodedString = btoa(binary);
      let openUrl = `${
        process.env.VUE_APP_KK_PREVIEW
      }/onlinePreview?url=${encodeURIComponent(encodedString)}`;
      // 追加一个参数告知分享范围，如果是所有人可下载，则不判断token,否则判断token
      let querys: any = {
        url: openUrl,
        userFileId: file.userFileId,
        shareKey: shareKey,
        userFileName: file.userFileName,
        extensionName: file.extensionName,
        shareId: this.shareId,
        scopeFlag: file.scope == "ALL" || !file.scope ? true : false,
      };
      let routeDatas = this.$router.resolve({
        name: "VIEW_File",
        query: querys,
      });
      window.open(routeDatas.href, "_blank"); // 新窗口打开
    } else if (imgEx.includes(file.extensionName)) {
      this.previewImgList = [
        {
          id: file.userFileId,
          url: this.getFileUrl(file),
        },
      ];
      this.$nextTick(() => {
        this.previewId = file.userFileId;
      });
    }
  }

  customRow(record: any) {
    return {
      on: {
        // 点击行
        dblclick: () => {
          return this.hadnleFile(record);
        },
        click: () => {
          let index = this.selectedRowKeys.indexOf(record.userFileId);
          if (index !== -1) {
            this.selectedRowKeys.splice(index, 1);
          } else {
            this.selectedRowKeys.push(record.userFileId);
          }
        },
      },
    };
  }

  downloadFile(value: any) {
    let clientId: string = creatClientId();
    // this.$apis.addClientId({ clientId }).then((res: any) => {
    // if (res.code === "200") {l
    const wrap = document.createElement("div");
    wrap.setAttribute("style", "display: none");
    document.body.appendChild(wrap);
    const frame = document.createElement("iframe"); //创建a对象
    frame.setAttribute(
      "src",
      `/star-doc/file/download?clientId=${clientId}&userFileId=${value.userFileId}&token=${this.token}&Tenant-Id=${this.companyId}`
    );
    frame.setAttribute("class", "download-iframe");
    wrap.appendChild(frame);
    let fileName = value.isDir
      ? value.userFileName
      : `${value.userFileName}.${value.extensionName}`;

    this.$apis.shareRecord({
      fileName,
      shareId: this.shareId,
      type: "download",
    });

    setTimeout(() => {
      document.body.removeChild(wrap);
    }, 60000);
  }

  // 下载
  handleDownload(value: any) {
    // if ("下载范围为所有") {
    if (value.scope == "ALL" || !value.scope) {
      this.downloadFile(value);
    } else {
      if (this.token) {
        this.downloadFile(value);
      } else {
        this.$message.warning(
          "该文件设置了仅Dana AI 智能体用户可下载，请先登录或注册成为平台用户方可进行后续操作"
        );
        let shareKey = new URL(window.location.href).searchParams.get(
          "shareKey"
        );
        const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
        // 打印新的 URL 或者进行其他操作
        console.log("新的newUrl", newUrl);
        setTimeout(() => {
          window.location.href = newUrl;
        }, 3000);
      }
    }
  }

  // 下载所有的
  downloadAllFile() {
    let clientId: string = creatClientId();
    const wrap = document.createElement("div");
    wrap.setAttribute("style", "display: none");
    document.body.appendChild(wrap);
    this.selectedRowKeys.forEach((userFileId) => {
      const frame = document.createElement("iframe"); //创建a对象
      frame.setAttribute(
        "src",
        `/star-doc/file/download?clientId=${clientId}&userFileId=${userFileId}&token=${this.token}&Tenant-Id=${this.companyId}`
      );
      frame.setAttribute("class", "download-iframe");
      wrap.appendChild(frame);

      wrap.appendChild(frame);
    });
    const file = this.shareFileList.find(
      (file: any) => file.userFileId === this.selectedRowKeys[0]
    );
    let fileName = file.isDir
      ? file.userFileName
      : `${file.userFileName}.${file.extensionName}`;
    fileName =
      this.selectedRowKeys.length > 1
        ? `${fileName}等${this.selectedRowKeys.length}个文件`
        : fileName;
    this.$apis.shareRecord({
      fileName,
      shareId: this.shareId,
      type: "download",
    });
    setTimeout(() => {
      document.body.removeChild(wrap);
    }, 60000);
  }

  handleDownloadAll() {
    if (this.selectedRowKeys.length < 1) {
      this.$message.warning("请至少选择一个文件");
      return;
    }

    // if ("下载范围为所有") {
    // 分享的列表中有任何一个文件的分享范围是所有人可下周
    if (
      this.shareFileList.some(
        (item: { scope: string }) => item.scope === "ALL" || !item.scope
      )
    ) {
      this.downloadAllFile();
    } else {
      if (this.token) {
        this.downloadAllFile();
      } else {
        this.$message.warning(
          "该文件设置了仅Dana AI 智能体用户可下载，请先登录或注册成为平台用户方可进行后续操作"
        );
        let shareKey = new URL(window.location.href).searchParams.get(
          "shareKey"
        );
        const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
        // 打印新的 URL 或者进行其他操作
        console.log("新的newUrl", newUrl);
        setTimeout(() => {
          window.location.href = newUrl;
        }, 3000);
      }
    }
  }

  // 保存
  handleSave(value: any) {
    if (this.token) {
      this.saveModalShow = true;
      this.saveOptions.checkIdList = [value.userFileId];
    } else {
      this.$message.warning("保存文件需要登录,请登录后保存");
      let shareKey = new URL(window.location.href).searchParams.get("shareKey");
      const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
      console.log("新的newUrl", newUrl);
      setTimeout(() => {
        window.location.href = newUrl;
      }, 1000);
    }
  }

  // 适配移动端下点击了保存
  handleSaveIsMobile(value: any) {
    // alert("保存请激活启动app或跳转下载页");
    if (this.token) {
      this.saveModalShow = true;
      this.saveOptions.checkIdList = [value.userFileId];
    } else {
      this.$message.warning("保存文件需要登录,请登录后保存");
      let shareKey = new URL(window.location.href).searchParams.get("shareKey");
      const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
      console.log("新的newUrl", newUrl);
      setTimeout(() => {
        window.location.href = newUrl;
      }, 1000);
    }
  }

  saveOk(targetId: string, sourceIds: any, teamId: any) {
    let query = {};
    let apiName = "userFileCopy";
    if (teamId) {
      query = {
        targetId,
        sourceIds,
        teamId,
      };
      apiName = "companyFileCopy";
    } else {
      query = {
        targetId,
        sourceIds,
      };
    }

    this.$apis[apiName](query).then((res: any) => {
      if (res.code == 200) {
        this.$message.success("文件保存成功");
        this.saveModalShow = false;

        // 如果是移动端，这时候展示去下载或者保存app的页面
        if (this.deviceClass == "isMobile") {
          // this.showOpenAppFlag=true
          window.location.href = "https://www.danaai.net/download";
        } else {
          // this.showOpenAppFlag=false
        }

        const file = this.shareFileList.find(
          (file: any) => file.userFileId === this.selectedRowKeys[0]
        );
        let fileName = file.isDir
          ? file.userFileName
          : `${file.userFileName}.${file.extensionName}`;
        fileName =
          this.selectedRowKeys.length > 1
            ? `${fileName}等${this.selectedRowKeys.length}个文件`
            : fileName;
        this.$apis.shareRecord({
          fileName,
          shareId: this.shareId,
          type: "forward",
        });
      }
    });
  }

  handleSaveAll() {
    if (this.selectedRowKeys.length < 1) {
      this.$message.warning("请至少选择一个文件");
      return;
    }

    // if (this.deviceClass == "isMobile") {
    // alert("跳转移动端app");
    // return;
    // 移动端的全选或多选保存，之前用return准备在return上面单独写方法，无需分开的话则可以把if (this.deviceClass == "isMobile") {这个判断直接去掉

    // }

    if (this.token) {
      this.saveModalShow = true;
      this.saveOptions.checkIdList = [...this.selectedRowKeys];
    } else {
      this.$message.warning("保存文件需要登录,请登录后保存");
      let shareKey = new URL(window.location.href).searchParams.get("shareKey");
      const newUrl = `${process.env.VUE_APP_FILE_VIVW_HISTORY_URL}/star-pan-web/fileShare?shareKey=${shareKey}`;
      console.log("新的newUrl", newUrl);
      setTimeout(() => {
        window.location.href = newUrl;
      }, 2500);
    }
  }

  // 拼接文件完整地址, 用作图片预览
  getFileUrl(file: any) {
    return `/star-doc/file/share/preview?topShareFileId=${this.parentFileId}&userFileId=${file.userFileId}`;
  }

  // 点击面包屑跳转到指定文件夹
  jumpToFolder(el: any, i: number) {
    // 最后一项不让点击
    if (i < this.breadcrumbList.length - 1) {
      if (el.id === "") {
        this.loading = true;
        this.queryShareFileList("getList");
        this.parentFileId = undefined;
        this.breadcrumbList = this.breadcrumbList.slice(0, i + 1);
      } else {
        this.queryNextShareFileList(el);
        this.breadcrumbList = this.breadcrumbList.slice(0, i + 1);
      }
    }
  }
  // 返回上一级
  toPreLevel() {
    if (this.breadcrumbList.length > 1) {
      let el = this.breadcrumbList[this.breadcrumbList.length - 2];
      if (el.id === "") {
        this.loading = true;
        this.queryShareFileList("getList");
        this.parentFileId = undefined;
        this.breadcrumbList.pop();
      } else {
        this.queryNextShareFileList(el);
        this.breadcrumbList.pop();
      }
    }
  }
  setDeviceClass() {
    const isMobile = this.$device.mobile; // 使用 vue-device-detector 提供的 API 检测设备是否为移动设备
    this.deviceClass = isMobile ? "isMobile" : "desktop";
  }

  //创建前钩子函数
  created(): void {}
  //钩子函数
  mounted(): void {
    this.setDeviceClass();

    console.log("this.deviceClass", this.deviceClass);
  }
}
